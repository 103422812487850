<template>
  <div>
    <v-menu
        ref="menu"
        v-model="menu2"
        :close-on-content-click="false"
        :nudge-right="40"
        :return-value.sync="time"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="290px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
            v-model="data.value"
            :label="data.title"
            prepend-icon="mdi-clock-time-four-outline"
            readonly
            v-bind="attrs"
            v-on="on"
        ></v-text-field>
      </template>
      <v-time-picker
          v-model="data.value"
          :label="data.title"
          :data="data"
          @input="sendData($event);menu = false"
          @change="sendData($event);menu = false"
          format="24hr"
          max-width="700"
          @keyup="sendData($event)"
      ></v-time-picker>
    </v-menu>
  </div>
</template>

<script>
/**
 * Displays a input field to change business information
 * @displayName TextField
 */
export default {
  name: "TimePicker",
 data(){
    return{
      menu: false
    }
 },
  props: {
    /**
     * Contains the data for the text field
     */
    data: {
      type: Object,
      default(){
        return {}
      }
    },
    rules: {
      type:Array,
      default(){
        return []
      }
    },
  },
  computed: {
    inputValue(){
      return {...this.data}
    }
  },
  methods: {
    /**
     * Sends the changed data for the text field to the parent
     * @public
     */
    sendData(){
      /**
       * Sends the text field value to the parent
       * @property {Object} data contains the new inputValue
       */
      this.$emit('sendData', this.inputValue)
    }
  }
}
</script>
