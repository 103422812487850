<template>
  <div class="row ps-0">
    <div class="col-12">
      <v-autocomplete
          :data="data"
          :label="data.title"
          :items="data.items"
          v-model="data.value"
          item-text="text"
          item-value="value"
          @change="sendData(data)"
          multiple
          small-chips
          deletable-chips
      ></v-autocomplete>
    </div>
  </div>
</template>

<script>
/**
 * Displays a input field to change business information
 * @displayName TextField
 */
export default {
  name: "TextField",
  props: {
    /**
     * Contains the data for the text field
     */
    data: {
      type: Object,
      default() {
        return {}
      }
    },
  },
  computed: {
    inputValue() {
      return {...this.data}
    }
  },
  methods: {
    /**
     * Sends the changed data for the text field to the parent
     * @public
     */
    sendData() {
      /**
       * Sends the text field value to the parent
       * @property {Object} data contains the new inputValue
       */
      this.$emit('sendData', this.inputValue)
    },
    newEntry(entity) {
      this.$emit('newEntry', {entity: entity})
    },
    getTooltip(entity){
      switch(entity){
        case'wedding':
          return 'Neue Hochzeit erstellen'
        case'task':
          return 'Neue Aufgabe erstellen'
        case'customer':
          return 'Neuen Kunden erstellen'
        case'vendor':
          return 'Neuen Dienstleister erstellen'
        case'event':
          return 'Neuen Termin erstellen'
      }
    }
  }
}
</script>
